<template>
    <div>
        <v-btn-toggle v-model="toggle_exclusive" multiple @change="clickFase">
            <v-btn class="ma-2" outlined>
                Previo
            </v-btn>
            <v-btn class="ma-2" outlined>
                Construcción
            </v-btn>
            <v-btn class="ma-2" outlined>
                Operación
            </v-btn>
            <v-btn class="ma-2" outlined>
                Abandono
            </v-btn>
        </v-btn-toggle>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
        />
        <proyecto-form ref="proyectoRef"></proyecto-form>
    </div>
</template>
<script>
    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    import ProyectoForm from "@/views/formulario/ProyectoForm.vue"
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import axios from 'axios';
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

    export default {
        name: 'BarChart',
        components: {
            Bar,
            ProyectoForm
        },
        props: {
            label: {
                type: Array,
                required: true
            },
            backgroundColors: {
                type: Array,
                required: true
            },
            datos: {
                type: Array,
                required: true
            },
            proyecto: {
                type: Object
            }
        },
        data() {
            return {
                chartData: {
                    labels: this.label,
                    datasets: [
                        {
                            label: 'Compromisos',
                            backgroundColor: this.backgroundColors, 
                            data: this.dataChart 
                        }
                    ]
                },
                chartOptions: {
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: (event, elements, chart) => {
                        if (elements.length !== 0) {
                            let indice = elements[0].index;
                            let labelClicado = this.label[indice];
                            this.$refs.proyectoRef.open(this.proyecto, true, labelClicado);
                        }         
                    },
                    plugins: {
                        datalabels: {
                            color: '#000',
                            align: 'end', 
                            formatter: (value, context) => {
                                let total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                if (value === 0){
                                    return ''
                                }
                                let percentage = (value / total) * 100;
                                let roundedPercentage = Math.round(percentage * 100) / 100;
                                return roundedPercentage.toFixed(2) + '% ' + '(' + value + ')';
                            }
                        },
                        legend: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            display: false ,
                            ticks: {
                                display: false 
                            }
                        },
                        y: {
                            display: true
                        }
                    }
                },
                toggle_exclusive: [0, 1, 2, 3],
                fases: [
                    { key: 0, value: 'PREVIO' },
                    { key: 1, value: 'CONSTRUCCION' },
                    { key: 2, value: 'OPERACION' },
                    { key: 3, value: 'ABANDONO' },
                ],
                dataChart: []
            }
        },
        mounted() {
            this.faseUF()
        },
        methods: {
            faseUF() {
                this.toggle_exclusive = [this.fases.findIndex(p => p.value == this.proyecto.fase.nombre.toUpperCase())]
                this.clickFase()
            },
            async clickFase() {
                if (this.toggle_exclusive.length > 0) {
                    const params = {
                        fases: this.toggle_exclusive
                    }
                    await axios.get("/compromisos/fase/" + this.proyecto.id, { params }).then((response) => {
                        this.chartData.datasets[0].data = response.data.body.items
                    }).catch((error) => {
                        console.error(error)
                    })
                } else {
                    this.chartData.datasets[0].data = []
                }
            }
        }
    }
</script>

  